import React from "react";
import {
  Document,
  Font,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Path,
  Svg,
} from "@react-pdf/renderer";

Font.register({
  family: "IBMPlexSans",
  src: "/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf",
});
Font.register({
  family: "IBMPlexSansBold",
  src: "/fonts/IBM_Plex_Sans/IBMPlexSans-SemiBold.ttf",
});
Font.register({
  family: "IBMPlexSansExtrabold",
  src: "/fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf",
});
interface CVProps {
  formData: {
    profilePicture?: string;
    firstName: string;
    lastName: string;
    address: {
      city: string;
      postalCode: string;
      country: string;
    };
    availableDate: string;
    age: number;
    englishLevel: string;
    drivingLicense: boolean;
    bio: string;
    experiences: {
      jobTitle: string;
      company: string;
      startDate: string;
      endDate: string;
      description: string;
      reasonForLeaving: string; // Ajout du champ raison du départ
    }[];
    education: {
      school: string;
      diploma: string;
      field: string;
      startDate: string;
      endDate: string;
      description: string;
    }[];
    skills: {
      skill: string;
      selected: boolean;
    }[];
    languages: string[];
    experienceYears: number;
    hasChildren: boolean;
    childrenDetails?: string;
    hasPetAllergies: boolean;
    petAllergyDetails?: string;
  };
}

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: "IBMPlexSans",
  },
  headerSection: {
    display: "flex",
    alignItems: "center",
    marginBottom: 40,
  },
  photo: {
    borderRadius: "50%",
    width: 100,
    height: 100,
    objectFit: "cover",
  },
  name: {
    fontSize: 22,
    fontWeight: "bold",
    fontFamily: "IBMPlexSansBold",
    marginTop: 10,
    marginBottom: 20,
  },
  contactInfoSection: {
    marginBottom: 30,
  },
  contactInfoItem: {
    fontSize: 12,
    lineHeight: 1.5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  sectionTitle: {
    fontSize: 16, // Augmenter légèrement la taille si nécessaire
    fontWeight: "extrabold", // Utilisez "bold" ou "extrabold"
    fontFamily: "IBMPlexSansExtrabold",
    marginBottom: 15,
  },
  profileSection: {
    marginBottom: 30,
  },
  text: {
    fontSize: 10,
    lineHeight: 1.5,
  },
  experienceSection: {
    marginBottom: 30,
  },
  boldText: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "IBMPlexSansBold",
  },
  skillsSection: {
    marginBottom: 30,
  },
  listItem: {
    fontSize: 12,
    marginBottom: 5,
  },
  educationSection: {
    marginBottom: 30,
  },
  horizontalLine: {
    borderBottom: "1px solid #C7A44C",
    marginBottom: 20,
    color: "#C7A44C",
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 3,
    marginBottom: 13,
  },
  iconAndTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  iconAndTitle2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  iconAndTitle3: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  iconAndTitle4: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  iconAndTitle5: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  iconAndTitle6: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  icon2: {
    width: 15,
    height: 15,
    marginRight: 8,
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
});

const formatDate = (dateString: string) => {
  if (!dateString) {
    return ""; // Retourne une chaîne vide si aucune date n'est fournie
  }
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalide"; // Retourne "Invalide" si la date n'est pas valide
  }
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const CandidatePDF: React.FC<CVProps> = ({ formData }) => {
  const selectedSkills = formData.skills.filter((skill) => skill.selected);
  console.log(formData)
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header Section */}
        <View style={styles.headerSection}>
          {formData.profilePicture && (
            <Image src={formData.profilePicture} style={styles.photo} />
          )}
          <Text style={styles.name}>
            {formData.firstName} {formData.lastName}
          </Text>
        </View>

        {/* Contact Information */}
        <View style={styles.iconAndTitle4}>
          {/* SVG Icon */}
          <Svg viewBox="0 0 448 512" style={styles.icon}>
            <Path
              d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z"
              fill="#C7A44C" // Couleur dorée (gold)
            />
          </Svg>
          <Text style={styles.sectionTitle}>Informations Personnelles :</Text>
        </View>
        <View style={styles.horizontalLine} />
        <View style={styles.contactInfoSection}>
          {/* Adresse */}
          <View style={styles.contactInfoItem}>
            <Svg viewBox="0 0 384 512" style={styles.icon2}>
              <Path
                d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                fill="#C7A44C"
              />
            </Svg>
            <Text style={styles.boldText}>
              Adresse: {formData.address?.city}, {formData.address?.postalCode},{" "}
              {formData.address?.country}
            </Text>
          </View>

          {/* Âge */}
          <View style={styles.contactInfoItem}>
            <Svg viewBox="0 0 448 512" style={styles.icon2}>
              <Path
                d="M86.4 5.5L61.8 47.6C58 54.1 56 61.6 56 69.2L56 72c0 22.1 17.9 40 40 40s40-17.9 40-40l0-2.8c0-7.6-2-15-5.8-21.6L105.6 5.5C103.6 2.1 100 0 96 0s-7.6 2.1-9.6 5.5zm128 0L189.8 47.6c-3.8 6.5-5.8 14-5.8 21.6l0 2.8c0 22.1 17.9 40 40 40s40-17.9 40-40l0-2.8c0-7.6-2-15-5.8-21.6L233.6 5.5C231.6 2.1 228 0 224 0s-7.6 2.1-9.6 5.5zM317.8 47.6c-3.8 6.5-5.8 14-5.8 21.6l0 2.8c0 22.1 17.9 40 40 40s40-17.9 40-40l0-2.8c0-7.6-2-15-5.8-21.6L361.6 5.5C359.6 2.1 356 0 352 0s-7.6 2.1-9.6 5.5L317.8 47.6zM128 176c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 48c-35.3 0-64 28.7-64 64l0 71c8.3 5.2 18.1 9 28.8 9c13.5 0 27.2-6.1 38.4-13.4c5.4-3.5 9.9-7.1 13-9.7c1.5-1.3 2.7-2.4 3.5-3.1c.4-.4 .7-.6 .8-.8l.1-.1s0 0 0 0s0 0 0 0s0 0 0 0s0 0 0 0c3.1-3.2 7.4-4.9 11.9-4.8s8.6 2.1 11.6 5.4c0 0 0 0 0 0s0 0 0 0l.1 .1c.1 .1 .4 .4 .7 .7c.7 .7 1.7 1.7 3.1 3c2.8 2.6 6.8 6.1 11.8 9.5c10.2 7.1 23 13.1 36.3 13.1s26.1-6 36.3-13.1c5-3.5 9-6.9 11.8-9.5c1.4-1.3 2.4-2.3 3.1-3c.3-.3 .6-.6 .7-.7l.1-.1c3-3.5 7.4-5.4 12-5.4s9 2 12 5.4l.1 .1c.1 .1 .4 .4 .7 .7c.7 .7 1.7 1.7 3.1 3c2.8 2.6 6.8 6.1 11.8 9.5c10.2 7.1 23 13.1 36.3 13.1s26.1-6 36.3-13.1c5-3.5 9-6.9 11.8-9.5c1.4-1.3 2.4-2.3 3.1-3c.3-.3 .6-.6 .7-.7l.1-.1c2.9-3.4 7.1-5.3 11.6-5.4s8.7 1.6 11.9 4.8c0 0 0 0 0 0s0 0 0 0s0 0 0 0l.1 .1c.2 .2 .4 .4 .8 .8c.8 .7 1.9 1.8 3.5 3.1c3.1 2.6 7.5 6.2 13 9.7c11.2 7.3 24.9 13.4 38.4 13.4c10.7 0 20.5-3.9 28.8-9l0-71c0-35.3-28.7-64-64-64l0-48c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 48-64 0 0-48c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 48-64 0 0-48zM448 394.6c-8.5 3.3-18.2 5.4-28.8 5.4c-22.5 0-42.4-9.9-55.8-18.6c-4.1-2.7-7.8-5.4-10.9-7.8c-2.8 2.4-6.1 5-9.8 7.5C329.8 390 310.6 400 288 400s-41.8-10-54.6-18.9c-3.5-2.4-6.7-4.9-9.4-7.2c-2.7 2.3-5.9 4.7-9.4 7.2C201.8 390 182.6 400 160 400s-41.8-10-54.6-18.9c-3.7-2.6-7-5.2-9.8-7.5c-3.1 2.4-6.8 5.1-10.9 7.8C71.2 390.1 51.3 400 28.8 400c-10.6 0-20.3-2.2-28.8-5.4L0 480c0 17.7 14.3 32 32 32l384 0c17.7 0 32-14.3 32-32l0-85.4z"
                fill="#C7A44C"
              />
            </Svg>
            <Text style={styles.boldText}>Âge: {formData.age}</Text>
          </View>

          {/* Niveau d'anglais */}
          <View style={styles.contactInfoItem}>
            <Svg viewBox="0 0 640 512" style={styles.icon2}>
              <Path
                d="M0 128C0 92.7 28.7 64 64 64l192 0 48 0 16 0 256 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64l-256 0-16 0-48 0L64 448c-35.3 0-64-28.7-64-64L0 128zm320 0l0 256 256 0 0-256-256 0zM178.3 175.9c-3.2-7.2-10.4-11.9-18.3-11.9s-15.1 4.7-18.3 11.9l-64 144c-4.5 10.1 .1 21.9 10.2 26.4s21.9-.1 26.4-10.2l8.9-20.1 73.6 0 8.9 20.1c4.5 10.1 16.3 14.6 26.4 10.2s14.6-16.3 10.2-26.4l-64-144zM160 233.2L179 276l-38 0 19-42.8zM448 164c11 0 20 9 20 20l0 4 44 0 16 0c11 0 20 9 20 20s-9 20-20 20l-2 0-1.6 4.5c-8.9 24.4-22.4 46.6-39.6 65.4c.9 .6 1.8 1.1 2.7 1.6l18.9 11.3c9.5 5.7 12.5 18 6.9 27.4s-18 12.5-27.4 6.9l-18.9-11.3c-4.5-2.7-8.8-5.5-13.1-8.5c-10.6 7.5-21.9 14-34 19.4l-3.6 1.6c-10.1 4.5-21.9-.1-26.4-10.2s.1-21.9 10.2-26.4l3.6-1.6c6.4-2.9 12.6-6.1 18.5-9.8l-12.2-12.2c-7.8-7.8-7.8-20.5 0-28.3s20.5-7.8 28.3 0l14.6 14.6 .5 .5c12.4-13.1 22.5-28.3 29.8-45L448 228l-72 0c-11 0-20-9-20-20s9-20 20-20l52 0 0-4c0-11 9-20 20-20z"
                fill="#C7A44C"
              />
            </Svg>
            <Text style={styles.boldText}>
              Niveau d'anglais: {formData.englishLevel}
            </Text>
          </View>

          {/* Permis de conduire */}
          <View style={styles.contactInfoItem}>
            <Svg viewBox="0 0 640 512" style={styles.icon2}>
              <Path
                d="M171.3 96L224 96l0 96-112.7 0 30.4-75.9C146.5 104 158.2 96 171.3 96zM272 192l0-96 81.2 0c9.7 0 18.9 4.4 25 12l67.2 84L272 192zm256.2 1L428.2 68c-18.2-22.8-45.8-36-75-36L171.3 32c-39.3 0-74.6 23.9-89.1 60.3L40.6 196.4C16.8 205.8 0 228.9 0 256L0 368c0 17.7 14.3 32 32 32l33.3 0c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80l130.7 0c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80l33.3 0c17.7 0 32-14.3 32-32l0-48c0-65.2-48.8-119-111.8-127zM434.7 368a48 48 0 1 1 90.5 32 48 48 0 1 1 -90.5-32zM160 336a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                fill="#C7A44C"
              />
            </Svg>
            <Text style={styles.boldText}>
              Permis de conduire: {formData.drivingLicense ? "Oui" : "Non"}
            </Text>
          </View>

          {/* Date de disponibilité */}
          <View style={styles.contactInfoItem}>
            <Svg viewBox="0 0 448 512" style={styles.icon2}>
              <Path
                d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L64 64C28.7 64 0 92.7 0 128l0 16 0 48L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-256 0-48 0-16c0-35.3-28.7-64-64-64l-40 0 0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L152 64l0-40zM48 192l80 0 0 56-80 0 0-56zm0 104l80 0 0 64-80 0 0-64zm128 0l96 0 0 64-96 0 0-64zm144 0l80 0 0 64-80 0 0-64zm80-48l-80 0 0-56 80 0 0 56zm0 160l0 40c0 8.8-7.2 16-16 16l-64 0 0-56 80 0zm-128 0l0 56-96 0 0-56 96 0zm-144 0l0 56-64 0c-8.8 0-16-7.2-16-16l0-40 80 0zM272 248l-96 0 0-56 96 0 0 56z"
                fill="#C7A44C"
              />
            </Svg>
            <Text style={styles.boldText}>
              Date de disponibilité: {formData.availableDate}
            </Text>
          </View>

          {/*Autres langues parlées */}
          <View style={styles.contactInfoItem}>
            <Svg viewBox="0 0 640 512" style={styles.icon2}>
              <Path
                d="M0 128C0 92.7 28.7 64 64 64l192 0 48 0 16 0 256 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64l-256 0-16 0-48 0L64 448c-35.3 0-64-28.7-64-64L0 128zm320 0l0 256 256 0 0-256-256 0zM178.3 175.9c-3.2-7.2-10.4-11.9-18.3-11.9s-15.1 4.7-18.3 11.9l-64 144c-4.5 10.1 .1 21.9 10.2 26.4s21.9-.1 26.4-10.2l8.9-20.1 73.6 0 8.9 20.1c4.5 10.1 16.3 14.6 26.4 10.2s14.6-16.3 10.2-26.4l-64-144zM160 233.2L179 276l-38 0 19-42.8zM448 164c11 0 20 9 20 20l0 4 44 0 16 0c11 0 20 9 20 20s-9 20-20 20l-2 0-1.6 4.5c-8.9 24.4-22.4 46.6-39.6 65.4c.9 .6 1.8 1.1 2.7 1.6l18.9 11.3c9.5 5.7 12.5 18 6.9 27.4s-18 12.5-27.4 6.9l-18.9-11.3c-4.5-2.7-8.8-5.5-13.1-8.5c-10.6 7.5-21.9 14-34 19.4l-3.6 1.6c-10.1 4.5-21.9-.1-26.4-10.2s.1-21.9 10.2-26.4l3.6-1.6c6.4-2.9 12.6-6.1 18.5-9.8l-12.2-12.2c-7.8-7.8-7.8-20.5 0-28.3s20.5-7.8 28.3 0l14.6 14.6 .5 .5c12.4-13.1 22.5-28.3 29.8-45L448 228l-72 0c-11 0-20-9-20-20s9-20 20-20l52 0 0-4c0-11 9-20 20-20z"
                fill="#C7A44C"
              />
            </Svg>
            <Text style={styles.boldText}>
              Autres langues parlées: {formData.languages.join(", ")}
            </Text>
          </View>
        </View>

        {/* Profile Section */}
        <View style={styles.profileSection}>
          <View style={styles.iconAndTitle3}>
            {/* SVG Icon */}
            <Svg viewBox="0 0 384 512" style={styles.icon}>
              <Path
                d="M192 0c-41.8 0-77.4 26.7-90.5 64L64 64C28.7 64 0 92.7 0 128L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64l-37.5 0C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM128 256a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM80 432c0-44.2 35.8-80 80-80l64 0c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16L96 448c-8.8 0-16-7.2-16-16z"
                fill="#C7A44C" // Couleur dorée (gold)
              />
            </Svg>
            <Text style={styles.sectionTitle}>Profil :</Text>
          </View>
          <View style={styles.horizontalLine} />
          <Text style={styles.text}>{formData.bio}</Text>
        </View>

        {/* Experience Section */}
        {formData.experiences.length > 0 && (
          <View style={styles.experienceSection}>
            <View style={styles.iconAndTitle2}>
              {/* SVG Icon */}
              <Svg viewBox="0 0 448 512" style={styles.icon}>
                <Path
                  d="M224 0a80 80 0 1 1 0 160A80 80 0 1 1 224 0zM436.8 382.8L373.5 462c-16.6 20.7-46.8 24.1-67.5 7.5c-17.6-14.1-22.7-38.1-13.5-57.7l-.8-.1c-38.9-5.6-74.3-25.1-99.7-54.8l0-36.8c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 48c0 .8 0 1.6 .1 2.4l101.4 50.7c23.7 11.9 33.3 40.7 21.5 64.4s-40.7 33.3-64.4 21.5L27.2 427.3c-1.1-.5-2.2-1.1-3.3-1.7c-4.9-2.8-9.2-6.4-12.6-10.6c-4.6-5.4-7.8-11.7-9.6-18.4c-3.3-12-1.9-25.2 4.8-36.6c.6-1.1 1.3-2.2 2-3.2L75.6 256.1c26.7-40.1 71.7-64.1 119.8-64.1l75.2 0c46.5 0 90.1 22.5 117.2 60.3l50.7 70.9c2.2 3 4 6.1 5.5 9.4c2.9 6.7 4.3 13.8 4 20.8c-.3 10.6-4.2 21-11.2 29.4zM320 332a44 44 0 1 0 -88 0 44 44 0 1 0 88 0z"
                  fill="#C7A44C" // Couleur dorée (gold)
                />
              </Svg>
              <Text style={styles.sectionTitle}>
                Expériences professionnelles :
              </Text>
            </View>
            <View style={styles.horizontalLine} />
            {formData.experiences.map((experience, index) => (
              <View key={index} style={{ marginBottom: 20 }}> {/* Augmentez la valeur si nécessaire */}
                <Text style={styles.boldText}>
                  {experience.jobTitle} - {experience.company}
                </Text>
                <Text style={styles.text}>
                  {formatDate(experience.startDate)}{" "}
                  {experience.endDate ? `- ${formatDate(experience.endDate)}` : "- Présent"}
                </Text>
                <Text style={styles.text}>
                  {experience.description}{" "}
                </Text>
                {experience.reasonForLeaving && (
                  <Text style={styles.text}>
                    Raison du départ: {experience.reasonForLeaving}{" "}
                  </Text>
                )}
              </View>
            ))}
          </View>
        )}

        {/* Education Section */}
        {formData.education.length > 0 && (
          <View style={styles.educationSection}>
            <View style={styles.iconAndTitle}>
              {/* SVG Icon */}
              <Svg viewBox="0 0 384 512" style={styles.icon}>
                <Path
                  d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-288-128 0c-17.7 0-32-14.3-32-32L224 0 64 0zM256 0l0 128 128 0L256 0zM160 240c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16l0 48 48 0c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-48 0 0 48c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-48-48 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16l48 0 0-48z"
                  fill="#C7A44C" // Couleur dorée (gold)
                />
              </Svg>
              <Text style={styles.sectionTitle}>Formation :</Text>
            </View>
            <View style={styles.horizontalLine} />
              {formData.education.map((edu, index) => (
                <View key={index} style={{ marginBottom: 20 }}> {/* Ajustez la marge si nécessaire */}
                  <Text style={styles.boldText}>
                    {edu.school} - {edu.diploma} ({edu.field})
                  </Text>
                  <Text style={styles.text}>
                    {edu.startDate} - {edu.endDate}
                  </Text>
                  <Text style={styles.text}>{edu.description}</Text>
                </View>
              ))}
          </View>
        )}

        {/* Skills Section */}
        {selectedSkills.length > 0 && (
          <View style={styles.skillsSection}>
            <View style={styles.iconAndTitle}>
              {/* SVG Icon */}
              <Svg viewBox="0 0 512 512" style={styles.icon}>
                <Path
                  d="M96 352L96 96c0-35.3 28.7-64 64-64l256 0c35.3 0 64 28.7 64 64l0 197.5c0 17-6.7 33.3-18.7 45.3l-58.5 58.5c-12 12-28.3 18.7-45.3 18.7L160 416c-35.3 0-64-28.7-64-64zM272 128c-8.8 0-16 7.2-16 16l0 48-48 0c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l48 0 0 48c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-48 48 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-48 0 0-48c0-8.8-7.2-16-16-16l-32 0zm24 336c13.3 0 24 10.7 24 24s-10.7 24-24 24l-160 0C60.9 512 0 451.1 0 376L0 152c0-13.3 10.7-24 24-24s24 10.7 24 24l0 224c0 48.6 39.4 88 88 88l160 0z"
                  fill="#C7A44C" // Couleur dorée (gold)
                />
              </Svg>
              <Text style={styles.sectionTitle}>Compétences :</Text>
            </View>
            <View style={styles.horizontalLine} />
            {selectedSkills.map((skill, index) => (
              <Text key={index} style={styles.listItem}>
                {skill.skill}
              </Text>
            ))}
          </View>
        )}

        {/* Questions Section */}
        <View style={styles.profileSection}>
          <View style={styles.iconAndTitle5}>
            {/* SVG Icon */}
            <Svg viewBox="0 0 576 512" style={styles.icon}>
              <Path
                d="M48 0C21.5 0 0 21.5 0 48L0 256l144 0c8.8 0 16 7.2 16 16s-7.2 16-16 16L0 288l0 64 144 0c8.8 0 16 7.2 16 16s-7.2 16-16 16L0 384l0 80c0 26.5 21.5 48 48 48l217.9 0c-6.3-10.2-9.9-22.2-9.9-35.1c0-46.9 25.8-87.8 64-109.2l0-95.9L320 48c0-26.5-21.5-48-48-48L48 0zM152 64l16 0c8.8 0 16 7.2 16 16l0 24 24 0c8.8 0 16 7.2 16 16l0 16c0 8.8-7.2 16-16 16l-24 0 0 24c0 8.8-7.2 16-16 16l-16 0c-8.8 0-16-7.2-16-16l0-24-24 0c-8.8 0-16-7.2-16-16l0-16c0-8.8 7.2-16 16-16l24 0 0-24c0-8.8 7.2-16 16-16zM512 272a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM288 477.1c0 19.3 15.6 34.9 34.9 34.9l218.2 0c19.3 0 34.9-15.6 34.9-34.9c0-51.4-41.7-93.1-93.1-93.1l-101.8 0c-51.4 0-93.1 41.7-93.1 93.1z"
                fill="#C7A44C" // Couleur dorée (gold)
              />
            </Svg>
            <Text style={styles.sectionTitle}>Questions :</Text>
          </View>
          <View style={styles.horizontalLine} />
          <Text style={styles.boldText}>
            Combien d’années d’expérience dans la garde d’enfants avez-vous ?
          </Text>
          <Text style={styles.text}>{formData.experienceYears}</Text>

          <Text style={styles.boldText}>Avez-vous des enfants ?</Text>
          <Text style={styles.text}>
            {formData.hasChildren ? "Oui" : "Non"}
          </Text>
          {formData.hasChildren && (
            <>
              <Text style={styles.boldText}>Détails sur les enfants:</Text>
              <Text style={styles.text}>{formData.childrenDetails}</Text>
            </>
          )}

          <Text style={styles.boldText}>
            Avez-vous des allergies aux animaux ?
          </Text>
          <Text style={styles.text}>
            {formData.hasPetAllergies ? "Oui" : "Non"}
          </Text>
          {formData.hasPetAllergies && (
            <>
              <Text style={styles.boldText}>Détails des allergies:</Text>
              <Text style={styles.text}>{formData.petAllergyDetails}</Text>
            </>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default CandidatePDF;
